<template>
    <div id="themed-content" :data-theme="theme" :class="{ 'dark': theme === 'dark'}">
        <img id="intrack-logo" src="./assets/horizontal_intrack_logo.png" width="175">
        <div id="theme-toggle">
            <theme-toggle />
        </div>
        <div id="content">
            <div id="satellite-list">
                <suspense>
                    <template #default>
                        <satellite-list />
                    </template>

                    <template #fallback>
                        <div class="placeholder-sat-list">
                            <h1 style="margin: 0 0 5px 0; text-align: left;">Recent</h1>
                            <div id="search">
                                <input type="text" id="search-field" placeholder="Loading..." disabled>
                                <div style="width: 26px;"></div>
                            </div>
                            <div id="skeleton-sat-list" style="width: 100%;">
                                <satellite-list-item-skeleton v-for="i in 20" :key="i" />
                            </div>
                        </div>
                    </template>
                </suspense>
            </div>
            
            <div class="divider"></div>
            
            <suspense v-if="selectedSatellite">
                <satellite-info />

                <template #fallback>
                    <div><p>Loading...</p></div>
                </template>
            </suspense>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

import SatelliteList from './components/SatelliteList.vue';
import SatelliteInfo from './components/SatelliteInfo.vue';
import ThemeToggle from './components/ThemeToggle.vue';
import SatelliteListItemSkeleton from './components/SatelliteListItemSkeleton.vue';

export default {
    name: 'App',
    components: {
        SatelliteList,
        SatelliteInfo,
        SatelliteListItemSkeleton,
        ThemeToggle,
    },
    setup() {
        const store = useStore();
        const selectedSatellite = computed(() => store.state.satellites.selectedSatelliteNCN);
        
        return {
            theme: computed(() => store.state.settings.theme),
            selectedSatellite,
        }
    }
}
</script>

<style>
    * {
        box-sizing: border-box;
        image-rendering: -webkit-optimize-contrast;
    }
    
    html, body {
        height: 100%;
        margin: 0;
    }

    #themed-content[data-theme="light"] {
        --main-page-color: white;
        
        --highlight-color: rgb(248, 248, 248);
        --highlight-hover-color: rgb(235, 235, 235);
        --secondary-highlight-color: rgba(252, 251, 251, 0.712);

        --good-color: rgba(137, 252, 147, 0.39);
        --good-hover-color: rgba(63, 248, 88, 0.521);

        --ok-color: rgba(255, 223, 117, 0.39);
        --ok-hover-color: rgba(253, 206, 52, 0.527);

        --bad-color: rgba(252, 137, 137, 0.39);
        --bad-hover-color: rgba(216, 46, 46, 0.411);

        --good-marker-color: rgba(63, 248, 88, 1);
        --ok-marker-color: rgba(253, 206, 52, 1);
        --bad-marker-color: rgba(216, 46, 46, 1);

        --toggle-color: rgb(9, 133, 227);

        --main-text-color: #2c3e50;
        --secondary-text-color: #848484;

        --error-message-color: red;

        --border-color: rgba(210, 210, 210, 0.5);
        --secondary-border-color: rgb(210, 210, 210);

        --button-background-hover-color: rgba(212, 212, 212, 0.5);

        --skeleton-accent-primary-color: #ddd;
        --skeleton-accent-secondary-color: rgb(230, 230, 230, 0.85);

        --skeleton-background: linear-gradient(90deg, transparent 0%, var(--skeleton-accent-secondary-color) 45%, var(--skeleton-accent-secondary-color) 55%, transparent 100%);

        --light-box-shadow-color: rgba(0, 0, 0, 0.065);
        --medium-box-shadow-color: rgba(51, 50, 50, 0.247);
        --dark-box-shadow-color: rgba(0, 0, 0, 0.7);

        --webkit-image-inverter: invert(0%); /* Safari/Chrome */
        --image-inverter: invert(0%);
        --image-opacity: 1;
    }

    #themed-content[data-theme="dark"] {
        --main-page-color: #121212;

        --highlight-color: #242424;
        --highlight-hover-color: #323232;

        --good-color: rgba(114, 255, 126, 0.5);
        --good-hover-color: rgba(63, 248, 88, 0.45);

        --ok-color: rgba(255, 222, 113, 0.6);
        --ok-hover-color: rgba(253, 206, 52, 0.6);

        --bad-color: rgba(255, 92, 92, 0.6);
        --bad-hover-color: rgba(216, 46, 46, 0.6);

        --good-marker-color: rgba(63, 248, 88, 0.9);
        --ok-marker-color: rgba(253, 206, 52, 0.9);
        --bad-marker-color: rgba(216, 46, 46, 0.9);

        --toggle-color: rgb(85, 85, 85);

        --main-text-color: rgba(255, 255, 255, 0.85);
        --secondary-text-color: #8D8D93;

        --error-message-color: rgba(255, 0, 0, 0.75);

        --border-color: rgba(210, 210, 210, 0.35);
        --secondary-border-color: rgba(155, 155, 155, 0.658);

        --button-background-hover-color: rgba(88, 88, 88, 0.5);
        --skeleton-accent-primary-color: #3C3C3C;
        --skeleton-accent-secondary-color: #444444;

        --skeleton-background: linear-gradient(90deg, transparent 0%, var(--skeleton-accent-secondary-color) 45%, var(--skeleton-accent-secondary-color) 55%, transparent 100%);

        --light-box-shadow-color: rgba(0, 0, 0, 0.05);
        --medium-box-shadow-color: rgba(51, 50, 50, 0.247);
        --dark-box-shadow-color: rgba(0,0,0,0.7);

        --webkit-image-inverter: invert(95%); /* Safari/Chrome */
        --image-inverter: invert(95%);
        --image-opacity: 0.75;
    }

    #themed-content {
        height: 100%;
        background-color: var(--main-page-color);
        color: var(--main-text-color);
        padding: 68px 8px 8px 8px;
        transition: 0.15s;
    }

    .dark {
        color-scheme: dark;
        transition: 0.15s;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        background-color: var(--main-page-color);
        color: var(--main-text-color);
        height: 100%;
        min-height: 100%;
    }

    #intrack-logo {
        position: absolute;
        top: 8px;
        left: 10px;
        transition: 0.15s;
    }

    #theme-toggle {
        position: absolute;
        top: 8px;
        right: 10px;
    }

    #content {
        display: flex;
        flex-direction: row;
        height: 100%;
        background-color: var(--main-page-color);
        transition: 0.15s;
    }

    .divider {
        width: 0;
        height: auto;
        margin: 0 50px;
        border: 1px solid var(--highlight-hover-color);
        transition: 0.15s;
    }

    #satellite-list {
        height: 100%;
        width: 326px;
    }

    #search-field {
        margin: 0 0 10px 1px;
        padding: 8px;
        width: 300px;
        height: 30px;

        border: 1px solid var(--secondary-border-color);
        border-radius: 5px;

        transition: 0.15s ease-out;
    }

    #search {
        display: flex;
        justify-content: flex-start;
    }

    .placeholder-sat-list {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 326px;
    }

    #skeleton-sat-list {
        overflow-y: auto;
        flex: 1 1 auto;
        padding-left: 1px;
    }

    @media screen and (max-width: 955px) {
        .divider {
            margin: 25px;
        }

        #satellite-list {
            max-width: 326px;
            width: 100%;
        }

        #search-field {
            max-width: 300px;
            width: 100%;
        }
    }
</style>
