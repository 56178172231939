<template>
    <div class="copy-to-clipboard-button">
        <img class="copy-to-clipboard-icon" src="../assets/copy_to_clipboard_resized.png" @click="copyToClipboard()">
        <div class="copy-text" :class="{ 'visible': displayCopiedIndicator}"><p>Copied!</p></div>
    </div>
</template>

<script>
import { ref } from 'vue';

export default {
    name: "CopyToClipboardButton",
    props: {
        text: {
            required: true,
            default: "",
            type: String
        },
        disabled: {
            required: false,
            default: false,
            type: Boolean
        }
    },
    setup(props) {
        const displayCopiedIndicator = ref(false);

        function copyToClipboard() {
            if (props.disabled) {
                return;
            }

            navigator.clipboard.writeText(props.text);
            displayCopiedIndicator.value = true;

            setTimeout(() => displayCopiedIndicator.value = false, 1100);
        }

        return {
            copyToClipboard,
            displayCopiedIndicator
        }
    }
}
</script>

<style scoped>
    .copy-to-clipboard-button {
        margin: 0 2px 0 auto;
        height: 30px;

        position: relative;
    }

    .copy-to-clipboard-icon {
        /* margin: 0 2px 0 auto; */
        height: 30px;
        opacity: 0.35;
        transition: 0.15s ease-out;

        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;

        position: relative;

        filter: var(--image-inverter);
        -webkit-filter: var(--webkit-image-inverter);
    }

    .copy-to-clipboard-icon:hover {
        cursor: pointer;
        opacity: 0.55;
    }

    .copy-text {
        position: absolute;

        z-index: 1;
        bottom: 35px;
        right: -11px;

        width: fit-content;
        height: fit-content;
        padding: 4px;

        font-size: 9pt;

        opacity: 0.95;
        background-color: var(--highlight-color);

        border: 1px solid var(--secondary-border-color);
        border-radius: 5px;
        box-shadow: 0px 0px 10px 1px var(--medium-box-shadow-color);

        transition: 0.125s ease-out;

        visibility: hidden;
        opacity: 0;
    }

    .copy-text > p {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: var(--main-text-color);

        margin: 0;
        font-size: 9pt;

        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
    }

    .visible {
        visibility: visible;
        opacity: 1;
    }
</style>