<template>
    <div class="sat-list-item" @click="selectSatellite()">
        <!-- <h3 class="li-title">{{ sat_no }}</h3> -->
        <div class="ncn-skeleton"></div>
        <!-- <button class="details-button" @click="selectSatellite()">Show Details</button> -->
        <div class="arrow"><p>➜</p></div>
    </div>
</template>

<script>
export default {
    name: "SatelliteListItemSkeleton"
}
</script>

<style scoped>
    .sat-list-item {
        width: 300px;
        height: 45px;

        margin: 0 0 10px 0;
        padding: 10px;

        display: flex;
        flex-direction: row;
        align-items: center;

        background-color: var(--highlight-color);

        border: 1px solid var(--border-color);
        border-radius: 5px;

        transition: 0.15s ease-out;

        user-select: none;
        -ms-user-select: none;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
    }
    .sat-list-item:hover {
        cursor: pointer;
        box-shadow: 0px 0px 10px 1px var(--medium-box-shadow-color);
        background-color: var(--highlight-hover-color);
    }

    .sat-list-item:hover .arrow {
        width: 30px;
    }

    .arrow {
        width: 40px;
        height: auto;
        margin-left: auto;

        transition: 0.15s ease-out;
    }

    .arrow p {
        margin: 0px;
        margin-top: -4px;
        font-size: 20pt;
        text-align: center;
    }

    .ncn-skeleton {
        width: 72px;
        height: 22px;
        border-radius: 5px;
        background-color: var(--skeleton-accent-primary-color);
        transition: 0.15s ease-out;
    }

    .ncn-skeleton::before {
        content: '';
        display: block;
        position: relative;

        left: -72px;
        top: 0;
        /* height: 12px;
        width: 150px; */
        height: 22px;
        width: 72px;

        background: var(--skeleton-background);
        animation: load 3s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }

    @media only screen and (max-width: 1015px) {
        .sat-list-item {
            max-width: 300px;
            width: 100%;
        }
    }
</style>