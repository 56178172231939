<template>
    <div class="sat-list-item" @click="selectSatellite()">
        <h3 class="li-title">{{ sat_no }}</h3>
        <!-- <button class="details-button" @click="selectSatellite()">Show Details</button> -->
        <div class="arrow"><p>➜</p></div>
    </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';

export default {
    name: "SatelliteListItem",
    props: {
        satData: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        const store = useStore();
        const selectedNCN = computed(() => store.state.satellites.selectedSatelliteNCN);
        const selectedSatellite = computed(() => store.state.satellites.selectedSatelliteData);
        const selected = ref(false);
        
        const bgColor = computed(() => selected.value ? `var(--${props.satData.status.toLowerCase()}-hover-color)` : `var(--${props.satData.status.toLowerCase()}-color)`);
        const bgHoverColor = computed(() => `var(--${props.satData.status.toLowerCase()}-hover-color)`);
        const boxShadow = computed(() => selected.value ? '0px 0px 10px 1px var(--medium-box-shadow-color)' : "none");

        watch(selectedNCN, async (ncn) => {
            if (ncn == props.satData.sat_no)
                await store.dispatch("satellites/selectSatellite");

            // await store.dispatch("satellites/selectSatellite", ncn);
            // console.log(store.state.selectedSatelliteData)
        })

        watch(selectedSatellite, (newSat) => {
            if (!newSat || newSat.sat_no !== props.satData.sat_no)
                selected.value = false;
            else
                selected.value = true;
        });

        function selectSatellite() {
            store.commit("satellites/selectSatelliteNCN", props.satData.sat_no);
        }

        return {
            sat_no: props.satData.sat_no,
            status: props.satData.status,
            selectSatellite,
            selected,
            bgColor,
            bgHoverColor,
            boxShadow,
        }
    }
}
</script>

<style scoped>
    .sat-list-item {
        width: 300px;
        height: 45px;

        margin: 0 0 10px 0;
        padding: 10px;

        display: flex;
        flex-direction: row;
        align-items: center;

        background-color: v-bind('bgColor');

        box-shadow: v-bind('boxShadow');
        border: 1px solid var(--border-color);
        border-radius: 5px;

        transition: 0.15s ease-out;

        user-select: none;
        -ms-user-select: none;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
    }
    .sat-list-item:hover {
        cursor: pointer;
        box-shadow: 0px 0px 10px 1px var(--medium-box-shadow-color);
        background-color: v-bind('bgHoverColor');
    }

    .sat-list-item:hover .arrow {
        width: 30px;
    }

    .li-title {
        margin: 0;
        color: var(--main-text-color);
    }

    .arrow {
        width: 40px;
        height: auto;
        margin-left: auto;

        transition: 0.15s ease-out;
    }

    .arrow p {
        margin: 0px;
        margin-top: -4px;
        font-size: 20pt;
        text-align: center;
    }

    @media only screen and (max-width: 1015px) {
        .sat-list-item {
            max-width: 300px;
            width: 100%;
        }
    }
</style>