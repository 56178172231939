import { createStore } from 'vuex'
// import createPersistedState from 'vuex-persistedstate';

import satellites from './modules/satellites';
import settings from './modules/settings';

export default createStore({
    modules: {
        satellites,
        settings
    },
    // plugins: [
    //     createPersistedState({
    //         paths: ["satellites"],
    //     })
    // ],
})
