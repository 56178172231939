const settings = {
	namespaced: true,
	state: {
		theme: "light"
	},
	mutations: {
		setTheme(state, themeName) {
            const validThemes = ["light", "dark"];
            themeName = themeName.toLowerCase();
            
            if (validThemes.includes(themeName)) {
              state.theme = themeName;
            }
        },
	}
};

export default settings;
