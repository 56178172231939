<template>
    <div class="skeleton-cesium-viewer"></div>
</template>

<script>
export default {
    name: "SkeletonCesiumViewer"
}
</script>

<style scoped>
    .skeleton-cesium-viewer {
        width: 567px;
        height: 450px;
        background-color: var(--skeleton-accent-primary-color);
        border-radius: 5px;
    }

    .skeleton-cesium-viewer::before {
        content: '';
        display: block;
        position: relative;

        left: -50px;
        top: 0;
        height: 50px;
        width: 50px;

        background: var(--skeleton-background);
        animation: load 3s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }
</style>