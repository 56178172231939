<template>
    <div class="timeline-container">
        <div class="endpoint-marking"><p class="tl-date">{{ markings[0] }}</p></div>
        <div class="tl">
            <div class="epoch-markers">
                <div class="epoch-marker" v-for="(pos, i) in epochMarkerPositions" :key="i" :style="`left: ${pos}px; background-color: var(--${historicalData[i].status.toLowerCase()}-marker-color);`">
                    <!-- <div v-if="i === historicalData[i].iod_index" class="cross">&#xd7;</div> -->
                </div>
            </div>
            <!-- <div class="crosses">
                <div class="cross" v-for=""></div>
            </div> -->
            <div class="middle-marking" v-for="mark in middleMarkings" :key="mark"><p class="tl-date">{{ mark }}</p></div>
        </div>
        <div class="endpoint-marking end"><p class="tl-date">{{ markings[markings.length - 1] }}</p></div>
    </div>
</template>

<script>
import { computed, watch } from 'vue';
import { useStore } from 'vuex';
import { parseISO } from '../scripts/utils.js';

export default {
    name: "TimeLine",
    async setup() {
        const store = useStore();
        const selectedSatelliteNCN = computed(() => store.state.satellites.selectedSatelliteNCN);

        watch(selectedSatelliteNCN, async () => await store.dispatch("satellites/selectCompleteSatellite"));

        await store.dispatch("satellites/selectCompleteSatellite");
        const historicalData = computed(() => store.state.satellites.completeSatelliteData);

        // await store.dispatch('satellites/until', () => store.state.satellites.selectedSatelliteData);
        // const historicalData = computed(() => store.state.satellites.completeSatelliteData ? store.state.satellites.completeSatelliteData : [store.state.satellits.selectedSatelliteData]);

        const tlStart = computed(() => new Date(parseISO(historicalData.value[historicalData.value.length - 1].epoch).setUTCHours(0, 0, 0, 0)));
        const tlEnd = computed(() => new Date(parseISO(historicalData.value[0].epoch).setUTCHours(24, 0, 0, 0)));

        const tlLength = computed(() => tlEnd.value.getTime() - tlStart.value.getTime());
        const dayDiff = computed(() => Math.ceil(tlLength.value / (1000 * 60 * 60 * 24)));

        const markings = computed(() => {
            if (tlLength.value <= 86400000) { // Same day
                return [
                    tlStart.value.toISOString().substring(0, 10),
                    "06:00:00",
                    "12:00:00",
                    "18:00:00",
                    tlEnd.value.toISOString().substring(0, 10)
                ];
             }

                const markingCount = Math.ceil(tlLength.value / 86400000);

                let marks = [tlStart.value.toISOString().substring(0, 10)]

                let dayInc = 1;

                if (dayDiff.value > 10 && dayDiff.value <= 20)
                    dayInc = 2;
                else if (dayDiff.value > 20 && dayDiff.value <= 30)
                    dayInc = 4;
                else if (dayDiff.value > 30 && dayDiff.value <= 180)
                    dayInc = 31;
                else if (dayDiff.value > 180 && dayDiff.value <= 730)
                    dayInc = 62;
                else if (dayDiff.value > 730)
                    dayInc = 365;

                for (let i = dayInc; i < markingCount; i += dayInc) {
                    console.log(i, dayInc, dayDiff.value)
                    const ts = Date.UTC(tlStart.value.getUTCFullYear(), tlStart.value.getUTCMonth(), tlStart.value.getUTCDate() + i, 0, 0, 0);
                    marks.push(new Date(ts).toISOString().substring(0, 10));
                }

                marks.push(tlEnd.value.toISOString().substring(0, 10))

                return marks;
        });

        console.log(markings.value)

        const middleMarkings = computed(() => markings.value.slice(1, markings.value.length - 1));

        const spacing = computed(() => {
            // const markingCount = tlLength.value <= 86400000 ? 4 : Math.ceil(tlLength.value / 86400000);
            return (563 / (markings.value.length - 1)).toString() + "px";
        });

        const epochMarkerPositions = computed(() => {
            let markers = [];

            if (!historicalData.value)
                return markers;

            for (let i = 0; i < historicalData.value.length; i++) {
                const epoch = parseISO(historicalData.value[i].epoch);
                let markingCount = tlLength.value <= 86400000 ? 1 : markings.value.length - 1;

                const normalisedPos = (epoch.getTime() - tlStart.value.getTime()) / (86400000 * markingCount);

                markers.push(normalisedPos * 563);
            }

            return markers;
        });

        return {
            historicalData,
            markings,
            middleMarkings,
            spacing,
            epochMarkerPositions,
        }
    }
}
</script>

<style scoped>
    .timeline-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        
        margin: 20px 0 35px 0;
        height: 20px;
    }

    .tl {
        height: 0;
        width: 563px;
        border: 1px solid var(--secondary-text-color);
        transition: 0.15s;

        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .endpoint-marking {
        height: 20px;
        width: 0;
        border: 1px solid var(--secondary-text-color);
        transition: 0.15s;
    }

    .endpoint-marking > p {
        width: 35px;
    }

    .end {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .middle-marking {
        height: 15px;
        width: 0;

        margin-left: v-bind(spacing);

        border: 1px solid var(--secondary-text-color);
        transition: 0.15s;

        display: flex;
        justify-content: center;
    }

    .tl-date {
        font-size: 9pt;
        color: var(--secondary-text-color);
        margin-top: 18px;
        width: 50px;
    }

    .epoch-markers {
        display: flex;
        flex-direction: row;
        position: relative;
        width: 0px;
        height: 15px;
    }

    .epoch-marker {
        width: 6px;
        height: 6px;
        position: absolute;

        top: 50%;
        transform: translateY(-50%);

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 100%;
    }

    .cross {
        width: 6px;
        height: 6px;
        padding: 1px 0 0 0px;
        font-size: 16pt;
        color: var(--main-text-color);
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>