<template>
    <div id="satellite-list">
        <h1 id="list-heading">Recent</h1>
        <div id="search">
            <input type="text" id="search-field" placeholder="Search..." v-model="searchQuery">
            <div id="space-filler"></div>
        </div>
        <div id="list-items">
            <satellite-list-item v-for="sat in filteredSatellites" :key="sat" :satData="sat"/>
        </div>
    </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';

import SatelliteListItem from './SatelliteListItem.vue';

export default {
    name: 'SatelliteList',
    components: {
        SatelliteListItem,
    },
    async setup() {
        const store = useStore();
        const searchQuery = ref("");

        const satellites = computed(() => store.state.satellites.ncns);
        const filteredSatellites = computed(
            () => store.state.satellites.ncns.filter(
                (satellite) => satellite.sat_no.toString().startsWith(searchQuery.value)
            )
        );

        let dataRefresher;

        onMounted(async () => {
            dataRefresher = await refreshDataContinuously();
        });

        onBeforeUnmount(() => {
            clearInterval(dataRefresher);
        });

        await store.dispatch("satellites/refreshData");

        async function refreshDataContinuously() {
            await setInterval(() => {
                store.dispatch("satellites/refreshData");
            }, 300000);
        }
        
        return {
            satellites,
            searchQuery,
            filteredSatellites
        }
    }
}
</script>

<style scoped>
    #satellite-list {
        display: flex;
        flex-direction: column;
    }

    #list-heading {
        margin: 0 0 5px 0;
        text-align: left;
    }

    #list-items {
        padding-left: 1px;
        flex: 1 1 auto;
        overflow-y: auto;
        width: 326px;
    }

    #search-field {
        margin: 0 0 10px 1px;
        padding: 8px;
        width: 300px;
        height: 30px;

        border: 1px solid var(--secondary-border-color);
        border-radius: 5px;

        transition: 0.15s ease-out;
    }

    #search {
        display: flex;
        justify-content: flex-start;
    }

    #space-filler {
        width: 26px;
    }

    @media only screen and (max-width: 1015px) {
        #satellite-list {
            max-width: 326px;
            width: 100%;
        }

        #list-items {
            max-width: 326px;
            width: 100%;
            padding-right: 6px;
        }

        #search-field {
            max-width: 300px;
            width: 100%;
        }
    }
</style>
