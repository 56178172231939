<template>
    <div></div>
</template>

<script>
import { computed, watch } from 'vue';
import { useStore } from 'vuex';
import { parseISO } from '../scripts/utils.js';
import { Ion, Viewer, JulianDate, ClockRange, Color, SampledPositionProperty, ReferenceFrame, Cartesian3, createWorldTerrain } from 'cesium';
import "cesium/Source/Widgets/widgets.css";
const satellite = require('satellite.js');

export default {
    name: "CesiumViewer",
    async setup() {
        const store = useStore();
        const selectedSatellite = computed(() => store.state.satellites.selectedSatelliteData);
        watch(selectedSatellite, async () => {
            await store.dispatch('satellites/until', () => store.state.satellites.selectedSatelliteData);
            displaySelectedSatellite();
        });

        // Before doing anything, make wait until all of the 
        await store.dispatch('satellites/until', () => store.state.satellites.selectedSatelliteData);

        Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJlZmU4NjM3ZC02NzlmLTQ3Y2QtYWMxYS01ODhkZjYwYjJmNTYiLCJpZCI6MTI0MzQ4LCJpYXQiOjE2NzYxODY2NTB9.3eSmIjTY96cHrGzFaj4XCmfJ0ArD29dgCnKJk_1-Zhc';

        // Initialize the Cesium Viewer in the HTML element with the `cesium-container` ID.
        // const viewer = new Viewer('cesium-container', {
        //     terrainProvider: createWorldTerrain(),
        //     baseLayerPicker: false, geocoder: false, homeButton: false, infoBox: false,
        //     navigationHelpButton: false
        // });

        const viewer = new Viewer("cesium-container", {
            terrainProvider: createWorldTerrain(),
            // imageryProvider: new TileMapServiceImageryProvider({
            //     // url: 'https://cdm-viewer.intrack.solutions/world.topo.bathy.200407.jpg', 
            //     url: 'https://mgrteststorage.blob.core.windows.net/$web/world.topo.bathy.200407.jpg', 
            //     fileExtension: 'jpg'
            // }),

            baseLayerPicker: false, geocoder: false, homeButton: false, infoBox: false,
            navigationHelpButton: false, sceneModePicker: false
        });

        let satelliteEntity = null;

        function displaySelectedSatellite() {
            if (!selectedSatellite.value.tle_line1) {
                if (satelliteEntity) {
                    try {
                        viewer.entities.removeById("sat");
                        viewer.entities.removeById("path");
                    } catch {
                        console.log("Tried to remove entity that doesn't exist");
                    }
                }

                satelliteEntity = null;
                return;
            }

            const satrec = satellite.twoline2satrec(selectedSatellite.value.tle_line1, selectedSatellite.value.tle_line2);
            const positionsOverTime = new SampledPositionProperty(ReferenceFrame.FIXED);
            const positions = [];

            const totalSeconds = (2 * 60) / (satrec.no / (2*Math.PI)); // (# revolutions * 60 secs) / ([rad/min] / 2pi)
            const timestepInSeconds = 60;
            const start = JulianDate.fromDate(parseISO(selectedSatellite.value.epoch));
            const stop = JulianDate.addSeconds(start, totalSeconds, new JulianDate());
            viewer.clock.startTime = start.clone();
            viewer.clock.stopTime = stop.clone();
            viewer.clock.currentTime = start.clone();
            viewer.timeline.zoomTo(start, stop);
            viewer.clock.multiplier = 40;
            viewer.clock.clockRange = ClockRange.LOOP_STOP;

            try {
                for (let j = 0; j < totalSeconds; j += timestepInSeconds) {
                    const time = JulianDate.addSeconds(start, j, new JulianDate());
                    const jsDate = JulianDate.toDate(time);

                    const positionAndVelocity = satellite.propagate(satrec, jsDate);
                    const gmst = satellite.gstime(jsDate);
                    const p = satellite.eciToEcf(positionAndVelocity.position, gmst);

                    const position = Cartesian3.fromElements(p.x*1000, p.y*1000, p.z*1000);
                    positions.push(position);
                    positionsOverTime.addSample(time, position);
                }

                if (!satelliteEntity) {
                    satelliteEntity = viewer.entities.add({
                        position: positionsOverTime,
                        point: { pixelSize: 6, color: Color.RED, outlineColor: Color.BLACK, outlineWidth: 1 },
                        id: "sat"
                    });
                } else {
                    satelliteEntity.position = positionsOverTime;
                }
                
            } catch (TypeError) {
                console.log("Could not propagate " + selectedSatellite.value.sat_no)
            }

            viewer.entities.removeById("path");

            viewer.entities.add({
                name: "Orbit Path",
                id: "path",
                polyline: {
                    positions: positions,
                    width: 2,
                    material: Color.ORANGE,
                }
            });
        }

        displaySelectedSatellite();

        return {
            viewer,
        }
    }
}
</script>

<style scoped>
    
</style>