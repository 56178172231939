<template>
    <div id="satellite-info">
        <h1 id="info-heading">Details</h1>
        <div id="info-panel" v-if="selectedSatellite">
            <pre class="tle-panel" v-if="selectedSatellite && selectedSatellite.tle_line1 && selectedSatellite.tle_line2">{{ selectedSatellite.tle_line1 + "\n" + selectedSatellite.tle_line2 }}<copy-to-clipboard-button :text="selectedSatellite.tle_line1 + '\n' + selectedSatellite.tle_line2" /></pre>
            <pre class="tle-panel" v-else>No TLE available</pre>
            
            <div id="extra-details">
                <suspense>
                    <time-line />
                </suspense>

                <p class="data-point left-aligned">Age: {{ age }}</p>
                <p class="data-point left-aligned">Position Uncertainty: {{ pos_unc }} m</p>
                <p class="data-point left-aligned">Velocity Uncertainty: {{ vel_unc }} m/s</p>
            </div>
            
            <div id="state-vector">
                <h2 class="left-aligned subheading">State Vector</h2>
                
                <state-vector-list-item svtype="UDL" style="margin-bottom: 8px;"/>
                <state-vector-list-item svtype="CCSDS"/>
            </div>

            <div id="visualisation">
                <h2 class="left-aligned subheading">Orbit Visualisation</h2>

                <div id="cesium-container" class="fullSize">
                    <suspense>
                        <template #default>
                            <cesium-viewer />
                        </template>

                        <template #fallback>
                            <skeleton-cesium-viewer />
                        </template>
                    </suspense>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { parseISO } from '../scripts/utils.js';

import StateVectorListItem from './StateVectorListItem.vue';
import CopyToClipboardButton from './CopyToClipboardButton.vue';
import CesiumViewer from './CesiumViewer.vue';
import SkeletonCesiumViewer from './SkeletonCesiumViewer.vue';
import TimeLine from './TimeLine.vue';

export default {
    name: "SatelliteInfo",
    components: {
        StateVectorListItem,
        CopyToClipboardButton,
        CesiumViewer,
        SkeletonCesiumViewer,
        TimeLine
    },
    async setup() {
        const store = useStore();
        const selectedSatelliteNCN = computed(() => store.state.satellites.selectedSatelliteNCN);
        const selectedSatellite = computed(() => store.state.satellites.selectedSatelliteData);

        watch(selectedSatelliteNCN, async () => await store.dispatch("satellites/selectSatellite"));

        // await store.dispatch("satellites/selectSatellite", selectedSatelliteNCN);
        await store.dispatch("satellites/selectSatellite");

        const displayCopiedIndicator = ref(false);

        const pos_unc = computed(() => selectedSatellite.value ? parseFloat((parseFloat(selectedSatellite.value.pos_unc) * 3).toPrecision(4)) : "-");
        const vel_unc = computed(() => selectedSatellite.value ? parseFloat((parseFloat(selectedSatellite.value.vel_unc) * 3).toPrecision(4)) : "-");
        
        const age = computed(() => {
            if (!selectedSatellite.value)
                return "-";

            const now = new Date(Date.now()); // should be UTC
            const epoch = parseISO(selectedSatellite.value.epoch);
            const msDiff = now.getTime() - epoch.getTime();

            const msPerMin = 60000;
            const msPerHour = msPerMin * 60;
            const msPerDay = msPerHour * 24;
            const msPerYear = msPerDay * 365;

            if (msDiff < 1000)
                return Math.round(msDiff).toString() + " ms";
            else if (msDiff >= 1000 && msDiff < msPerMin)
                return Math.round(msDiff / 1000).toString() + " s";
            else if (msDiff >= msPerMin && msDiff < msPerHour)
                return Math.round(msDiff / msPerMin).toString() + " minutes";
            else if (msDiff >= msPerHour && msDiff < msPerDay)
                return Math.round(msDiff / msPerHour).toString() + " hours";
            else if (msDiff >= msPerDay && msDiff < msPerYear)
                return Math.round(msDiff / msPerDay).toString() + " days";

            return Math.round(msDiff / msPerYear).toString() + " years";
        });

        return {
            selectedSatellite,
            displayCopiedIndicator,
            pos_unc,
            vel_unc,
            age,
        }
    }
}
</script>

<style scoped>
    #satellite-info {
        flex: 1 1 auto;

        display: flex;
        flex-direction: column;

        transition: 0.15s ease-out;
    }

    #info-heading {
        margin: 0 0 5px 0;
        text-align: left;
    }

    #info-panel {
        flex: 1 1 auto;
        overflow-y: auto;
    }

    #extra-details {
        margin-top: 15px;
    }

    #cesium-container {
        height: 450px;
        width: 567px;
        border-radius: 5px;
    }

    .data-point {
        margin: 5px 0 0 0;
    }

    .tle-panel {
        width: 568px;
        padding: 8px;

        font-size: 10pt;

        border-radius: 5px;
        border: 1px solid var(--highlight-hover-color);
        background-color: var(--highlight-color);
        text-align: left;

        display: flex;
        align-items: center;
        position: relative;
        
        transition: 0.15s ease-out;
    }

    .left-aligned {
        text-align: left;
    }

    .subheading {
        margin: 20px 0 8px 0;
    }

    @media screen and (max-width: 850px) {
        .tle-panel {
            max-width: 559px;
            width: 100%;
        }
    }
</style>
