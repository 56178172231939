<template>
    <div class="toggle">
        <label class="switch">
            <input type="checkbox" @click="toggleTheme()" :checked="initiallyChecked">
            <span class="slider"></span>
        </label>
    </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
    name: "ThemeToggle",
    setup() {
        const store = useStore();
        const currentTheme = computed(() => store.state.settings.theme);

        function toggleTheme() {
            if (currentTheme.value === "dark") {
                 store.commit("settings/setTheme", "light");
            } else {
                store.commit("settings/setTheme", "dark");
            }
        }

        let initiallyChecked = true;

        if (currentTheme.value === "light") {
            initiallyChecked = false;
        }

        return {
            store,
            toggleTheme,
            initiallyChecked,
        }
    }    
}
</script>

<style scoped>
    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 34px;
        background-color: var(--toggle-color);
        transition: .2s;
    }

    .slider:before {
        position: absolute;
        
        content: "";
        
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        border-radius: 50%;
        
        background-color: white;
        background-image: url('../assets/sun.png');
        background-size: 70%;
        background-repeat: no-repeat;
        background-position: center center;

        transition: 0.2s;
    }

    input:checked + .slider {
        background-color: var(--toggle-color);
    }

    input:focus + .slider {
        box-shadow: 0 0 1px var(--toggle-color);
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);

        background-image: url('../assets/moon.png');
        background-size: cover;
    }
</style>