export function parseISO(isoString) {
    const year = isoString.substring(0, 4);
    const month = isoString.substring(5, 7) - 1; // months are 0-indexed
    const day = isoString.substring(8, 10);
    const hour = isoString.substring(11, 13);
    const minute = isoString.substring(14, 16);
    const second = isoString.substring(17, 19);
    const ms = isoString.length >= 23 ? isoString.substring(20, 23) : 0;

    return new Date(Date.UTC(year, month, day, hour, minute, second, ms));
}