<template>
    <div class="sv-list-item left-aligned">
        <div class="sv-li-heading" :class="{ 'heading-bottom-border': open }" @click="toggleSV()">
            <h3 class="left-aligned sv-type-title">{{ svtype }}</h3>
            <img class="expand-arrow" src="../assets/down_arrow.png" :class="{ 'flipped': open }" />
        </div>

        <pre class="sv-content" v-if="open">{{ stateVector }}<copy-to-clipboard-button :text="stateVector" /></pre>
    </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

import CopyToClipboardButton from "./CopyToClipboardButton.vue"

export default {
    name: "StateVectorListItem",
    components: {
        CopyToClipboardButton,
    },
    props: {
        svtype: {
            required: true,
        }
    },
    setup(props) {
        const store = useStore();
        const open = ref(false);
        const selectedSatellites = computed(() => store.state.satellites.selectedSatelliteData);

        const stateVector = computed(() => {
            if (props.svtype === "CCSDS") {
                // process CCSDS state vector here
                return "This feature isn't implemented yet";
            }

            // Proccess UDL state vector
            const udlSV = {
                "idStateVector": selectedSatellites.value.id,
                "epoch": selectedSatellites.value.epoch,
                "satNo": selectedSatellites.value.sat_no,
                "xpos": selectedSatellites.value.mean[0],
                "ypos": selectedSatellites.value.mean[1],
                "zpos": selectedSatellites.value.mean[2],
                "xvel": selectedSatellites.value.mean[3],
                "yvel": selectedSatellites.value.mean[4],
                "zvel": selectedSatellites.value.mean[5],
                "cov": selectedSatellites.value.covariance,
                "posUnc": selectedSatellites.value.pos_unc * 3,
                "velUnc": selectedSatellites.value.vel_unc * 3,
                "dragCoeff": selectedSatellites.value.mean[6],
                "source": selectedSatellites.value.source
            };

            return JSON.stringify(udlSV, null, 4);
        });

        function toggleSV() {
            open.value = !open.value;
        }

        return {
            open,
            stateVector,
            toggleSV,
        }
    }
}
</script>

<style scoped>
    .left-aligned {
            text-align: left;
    }

    .sv-list-item {
        /* padding: 8px; */

        width: 567px;

        border-radius: 5px;
        border: 1px solid var(--highlight-hover-color);
        background-color: var(--secondary-highlight-color);
        
        text-align: left;
        display: flex;
        flex-direction: column;

        transition: 0.15s ease-out;
    }

    .sv-li-heading {
        display: flex;
        align-items: center;

        padding: 6px;

        user-select: none;
        -moz-user-select: none;
        -khtml-user-select: none;
        -ms-user-select: none;
        -webkit-user-select: none;
    }

    .heading-bottom-border {
        border-bottom: 1px solid var(--highlight-hover-color);
    }

    .sv-li-heading:hover {
        /* background-color: var(--highlight-color); */
        cursor: pointer;
    }

    .sv-type-title {
        margin: 0;
    }

    .expand-arrow {
        height: 10px;
        margin: 0 5px 0 auto;
        
        filter: var(--image-inverter);
        -webkit-filter: var(--webkit-image-inverter);

        transition: 0.15s ease-out;
    }

    .flipped {
        transform: rotateX(180deg);
    }

    .sv-content {
        width: 100%;

        font-size: 10pt;
        
        margin: 0;
        padding: 8px;
        text-align: left;

        background-color: var(--highlight-color);
        border-radius: 0 0 5px 5px;

        display: flex;
        align-items: flex-start;
        position: relative;
    }

    .sv-buttons {
        margin-left: auto;
    }

    @media screen and (max-width: 850px) {
        .sv-list-item {
            max-width: 559px;
            width: 100%;
        }
    }
</style>