// import { parseISO } from '../../scripts/utils.js';

const satellites = {
	namespaced: true,
	state: {
		selectedSatelliteNCN: null,
		allSatellites: [],
		allData: {},
		ncns: [],
		cache: {},
		selectedSatelliteData: null,
		completeSatelliteData: [],
	},
	mutations: {
		selectSatelliteData(state, satData) {
			state.selectedSatelliteData = satData;
		},
		selectCompleteSatelliteData(state, satData) {
			state.completeSatelliteData = satData;
		},
		selectSatelliteNCN(state, ncn) {
			state.selectedSatelliteNCN = ncn;
		},
		cache(state, { ncn, data }) {
			state.cache[ncn] = data;
		},
		setData(state, data) {
			state.allSatellites = data;
		},
		setNCNs(state, ncns) {
			state.ncns = ncns;
		},
		setAllData(state, data) {
			state.allData = data;
		}
	},
	actions: {
		async refreshData(context) {
			let response = await fetch("https://sact-dlo-interface.azurewebsites.net/api/GetNCNs?code=tJll9YH_k0mOsnnJIQeRyd5_KL85-kzlamMk70oll8_lAzFufwMVeg%3D%3D");
			let data = await response.json();
			context.commit("setNCNs", data);

			for (let i = 0; i < context.state.ncns.length; i++) {
				if (context.state.selectedSatelliteNCN &&
						context.state.ncns[i] === context.state.selectedSatelliteNCN) {
					context.commit("selectSatellite", null);
					break;
				}
			}
		},
		async selectSatellite(context) {//, ncn) {
			// if (!ncn) {
			// 	context.commit("selectSatelliteData", null);
			// 	context.commit("selectSatelliteNCN", null);
			// }

			// if (ncn in context.state.cache) {
			// 	context.commit("selectSatelliteData", context.state.cache[ncn]);
			// 	return;
			// }

			let response = await fetch(
				"https://sact-dlo-interface.azurewebsites.net/api/GetSatellite?code=OqvTVI732IXraDX0mezEZS0q8I2_QyHMY4RYmeQBXGbYAzFup_7CkA%3D%3D",
				{ method: "POST", body: JSON.stringify({ "ncn": context.state.selectedSatelliteNCN, "all": "false" }), mode: "cors" }
			);
			let data = await response.json();

			context.commit("selectSatelliteData", data);
			// context.commit("cache", { ncn: data.sat_no, data: data });
		},
		async selectCompleteSatellite(context) {//, ncn) {
			// if (!ncn) {
			// 	context.commit("selectCompleteSatelliteData", null);
			// 	context.commit("selectSatelliteNCN", null);
			// }

			// if (ncn in context.state.cache) {
			// 	context.commit("selectCompleteSatelliteData", context.state.cache[ncn]);
			// 	return;
			// }

			let response = await fetch(
				"https://sact-dlo-interface.azurewebsites.net/api/GetSatellite?code=OqvTVI732IXraDX0mezEZS0q8I2_QyHMY4RYmeQBXGbYAzFup_7CkA%3D%3D",
				{ method: "POST", body: JSON.stringify({ "ncn": context.state.selectedSatelliteNCN, "all": "true" }), mode: "cors" }
			);
			let data = await response.json();

			context.commit("selectCompleteSatelliteData", data);
			// context.commit("cache" , { ncn: data.sat_no, data: data });
		},
		/* A helper function that waits for a condition to be true, using async/await. This
        allows Suspense components to work when waiting for a condition to become true. */
		async until(context, conditionFunction) {
			const poll = resolve => {
				if (conditionFunction()) resolve();
				else setTimeout(() => poll(resolve), 100);
			}

			return new Promise(poll);
		}
	}
};

export default satellites;
